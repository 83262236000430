// WARNING, do not touch the double quote (") chars used in version, commitHash, and installationDate, it used by the CI/CD
export const envBase = {
  "czamApiUrl": "/czam-api",
  "restrictionsApiUrl": "/casino-restrictions-api",
  "casinoInjApiUrl": "/casino-inj-api",
  "swiftSaleApiUrl": "/swift-sale-api",
  "appsApiUrl": "/user-apps-api",
  "ocmConnectApiUrl": "/ocm-connect-api",
  "customerExtendedApiUrl": "/api-fiche-client",
  "casinoLoyaltyApiUrl": "/casino-loyalty-api",
  "walletApiUrl": "/wallet-api",
  "notificationCenterApiUrl": "/notifications-api",
  "hotelUnifiedDataApiUrl": "/hotel-unifieddata-api",
  "datascienceApiUrl": "/datascience-api",
  "version": "1.19.1.4048",
  "commitHash": "5a257e4968e4db320dc0156b9ebf5e8c2acfcde4",
  "packageGenerationDate": "2025-02-12T10:17:44Z",
  "asyncClientEntry": false,
  "numberOfSearchChar": 4,
  daysBeforeSignalCardExpireSoon: 15
};
